<template>
  <component
    :value="selectedInfluencer"
    :is="useCombobox ? 'v-combobox' : 'v-autocomplete'"
    :items="searchItems"
    :append-icon="null"
    :disabled="disabled"
    :loading="isLoadingSearchResults"
    return-object
    hide-details
    no-filter
    full-width
    clearable
    item-text="username"
    item-value="id"
    :hide-no-data="hideNoData"
    :search-input.sync="searchQuery"
    @update:search-input="doSearch"
    @click:clear="handleClearEvent"
    @mousedown="handleClearEvent"
    @keydown="handleClearEvent"
    @input="handleInputEvent"
    name="user-search"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- Show loader while the data is loading -->
    <template v-slot:no-data>
      <div v-if="isLoadingSearchResults" class="text-center py-3">
        <v-progress-circular
          :width="3"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="text-center py-2">
        No results found.
      </div>
    </template>

    <!-- Show user profile list for search results -->
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item
        ripple
        two-line
        v-bind="attrs"
        v-on="on"
        :class="listItemClass"
      >
        <v-list-item-action class="mr-2">
          <v-avatar height="36" width="36">
            <img :src="proxyUrl(item.picture)" />
          </v-avatar>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            @{{ item.username }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ item.fullname }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <div class="text-body-2" v-if="item.followers">
            {{ nFormatter(item.followers) }}
          </div>
        </v-list-item-action>
      </v-list-item>
    </template>

    <!-- Show the selection preview -->
    <template v-slot:selection="{ item }">
      <profile-chip
        :platform="item.platform"
        disable-click
        :data="item"
        small
      />
    </template>
  </component>
</template>

<script>
// Import vuetify components
import { VCombobox, VAutocomplete } from 'vuetify/lib'

// Import child components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")

// Export the SFC
export default {
  // Name of the component
  name: "ProfileSelector",

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    listItemClass: {
      type: String,
      required: false,
      default: null
    },

    useCombobox: {
      type: Boolean,
      required: false,
      default: true
    },

    hideNoData: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Register children components
  components: {
    VCombobox,
    VAutocomplete,
    ProfileChip
  },

  // Define local data variables
  data: () => ({
    searchQuery: "",
    isLoadingSearchResults: false,

    searchItems: [],

    // for handling debounce on type
    _timerId: null,

    // to cancel previous request if a new one is needed
    abortControllers: []
  }),

  // Define local computable variables
  computed: {
    /**
     * Get the currently selected platform
     *
     * @returns {String|Null}
     */
    selectedPlatform() {
      return this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id)
    },

    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
     selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Make API request to fetch the select options for autocomplete
     *
     * @returns {void}
     */
    doSearch() {
      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        // if input is empty, don't continue
        if (!this.searchQuery) {
          // reset the search results
          this.searchItems = []

          return
        }

        // Cancel pending network requests
        for (const item of this.abortControllers) {
          item.controller.abort()
        }

        // Make network requests in vuex store
        const id = Symbol()
        const controller = new AbortController()

        // Push this controller to the list
        this.abortControllers.push({ id, controller, query: this.searchQuery })

        // Show the loader while request is being made
        this.isLoadingSearchResults = true

        try {
          const queryParams = new window.URLSearchParams({
            platform: this.selectedPlatform,
            q: this.searchQuery
          })

          const response = await axios({
            url: `/api/campaign-tracking/${this.overview.model.id}/influencers/search/?${queryParams}`,
            signal: controller.signal
          })

          // the API responds like
          this.searchItems = response.data
        } catch (error) {
          // log using the helper function
          logger({ type: "Network Error", error })
        } finally {
          this.isLoadingSearchResults = false

          // remove the controller from cancellable array
          const index = this.abortControllers.findIndex((item) => item.id === id)
          this.abortControllers.splice(index, 1)
        }
      }, 500)
    },

    /**
     * Handle the input event
     *
     * @returns {void}
     */
    handleInputEvent(value) {
      // If the value is an object
      if (typeof value === "object") {
        // Mark this as the selected influencer
        this.$store.dispatch("campaignTracking/updateSelectedInfluencer", {
          id: this.overview.model.id,
          value: value || null
        })

        // Reset the search results
        this.searchQuery = ""
        this.searchItems = []
      }
    },

    /**
     * Handle the clear event
     *
     * @returns {void}
     */
    handleClearEvent() {
      // If there's no selected influencer
      if (!this.selectedInfluencer) {
        return
      }

      // Reset the search results
      this.searchQuery = ""
      this.searchItems = []

      // Remove the selected influencer
      this.$store.dispatch("campaignTracking/updateSelectedInfluencer", {
        id: this.overview.model.id,
        value: null
      })
    }
  },
}
</script>
